import request from '@/utils/request'
//诊断记录一览查询
export function listPage(query) {
    return request({
      url: '/diagnoseGinseng/listPage',
      method: 'get',
      params:query
    })
  }
  //诊断记录批量导出
  export function listDiagnoseExport(data) {
    return request({
      url: '/diagnoseGinseng/listDiagnoseExport',
      method: 'post',
      responseType: "blob",
      data
    })
  }

//顾问服务一览查询
export function communicationsGinseng(query) {
    return request({
      url: '/communicationsGinseng/listPage',
      method: 'get',
      params:query
    })
  }
  //顾问服务详情
  export function listDetailPage(query) {
    return request({
      url: '/communicationsGinseng/listDetailPage',
      method: 'get',
      params:query
    })
  }
 